.ng-select {
  padding: 0;
  font: $base-font;
  border: 1px solid $row-bg-hover;
  border-radius: 6px;

  .ng-select-container {
    cursor: pointer !important;
    &.ng-appearance-outline {
      height: 42px;
      background-color: $row-bg-hover;
      border-radius: 6px;
      padding: 0 10px;
      display: flex;
      align-items: center;

      .ng-placeholder {
        color: $header-grey !important;
      }

      .ng-arrow-wrapper {
        display: flex;
        height: 15px;
        width: 15px;
        margin-right: 7px;

        .ng-arrow {
          background-image: url('/assets/images/arrow-drop-down-24px.svg');
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          height: auto;
          transition: all .2s ease;
          transform: translateY(-1px);
        }
      }
      &.ng-has-value {
        .ng-placeholder {
          display: none;
        }
      }
    }
    .ng-value-container {
      box-sizing: inherit;
      display: flex;
      align-items: center;
      .ng-input{
        > input {
          cursor: pointer !important;
        }
      }
    }
  }
  .ng-value-container {
    .ng-input {
      padding: 0 10px;
    }
    .ng-value {
      color: $row-text-grey;
    }
  }
  &.ng-select-focused .ng-select-container .ng-placeholder,
  &.ng-select-opened .ng-select-container .ng-placeholder {
    transform: none;
  }
  .ng-dropdown-panel {
    background-color: $white;
    margin-top: 2px;
    min-width: 100%;
    width: auto;
    .ng-dropdown-panel-items {
      max-height: 160px !important;
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%) !important;
      .ng-option {
        padding: 8px;
        transition: all .3s ease;
        &.ng-option-marked,
        &.ng-option-selected {
          background-color: $silver-ten;
        }
      }
    }
  }
  &.app-ng-select {
    cursor: pointer;
    .ng-clear-wrapper {
      display: flex;
      align-items: center;
      transform: translateX(5px);
      width: 24px;
      height: 24px;
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('/assets/images/sprite.png');
        background-repeat: no-repeat;
        background-position: -148px -72px;
      }
      .ng-clear {
        display: none;
      }
    }
    &.ng-select-opened {
      .ng-arrow-wrapper {
        .ng-arrow {
          transform: rotate(180deg);
        }
      }
    }
    &.ng-select-disabled {
      pointer-events: none;

      .ng-select-container.ng-appearance-outline {
        background-color: $disabled-bg;
        border-color: $disabled-border;
      }

      .ng-select-container .ng-arrow-wrapper {
        opacity: .7;
      }
    }
    &.has-error {
      border-color: $hot-steal;
    }
    &:hover, &:focus {
      border-color: $slate-ten;
    }
    // custom scrollbar
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #FFFFFF;
      border-radius: 4px;
      background: #FFFFFF;
    }
    ::-webkit-scrollbar-thumb {
      background: #A9A9A9;
      border-radius: 4px;
    }
  }
}
.ng-dropdown-panel.app-ng-select {
  .ng-dropdown-panel-items {
    background-color: $white;
    max-height: 160px !important;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%) !important;
    .ng-option {
      padding: 10px;
      transition: all .2s ease;
      &.ng-option-selected {
        background-color: $row-bg-hover-5;
      }
      &:hover {
        background-color: $row-bg-hover;
      }
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked.ng-option-disabled {
        background-color: $white;
      }
    }

    .ng-option:has(.is-current) {
      background-color: $primary-button-blue;
      color: $white;
    }
  }
  &.status-select {
    .ng-dropdown-panel-items {
      max-height: max-content !important;
    }
  }
}
